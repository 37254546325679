<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <template v-if="!isLoading">
      <div class="row" >
          <div class="col-md-8">
            <div v-show="!isRegister" class="widget white-bg friends-group clearfix">
              <div class="alert bg-success alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Saudara sudah terdaftar di SPADA UM Metro! </div>
              <small class="text-muted">Nama Lengkap </small>
              <p>{{ profil.lastname }}</p>
              <small class="text-muted">Email Terdaftar di SPADA</small>
              <p>{{ profil.email }}</p>
              <small class="text-muted">Username Login SPADA</small>
              <p>{{ profil.username }}</p>
              <small class="text-muted"></small>
              <p></p>
            </div>
            <div class="card" v-show="isRegister" >
              <div class="card-header card-default">
                <p id="idExsampel" class="text-muted" >
                  Halaman Informasi Akun SPADA
                </p>
              </div>
              <div class="card-body padding-t-0">
                <div class="alert bg-warning alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Saudara belum memiliki Akun SPADA UM Metro, Klik tombol Registrasi Akun! </div>
                <router-link to="/registrasi-akun-spada" v-show="isRegister" href="javascript: void(0);" class="btn btn-success btn-rounded box-shadow">Registrasi Akun</router-link>
              </div>
            </div>
          </div>
        </div>
    </template>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
import Loading from 'vue-loading-overlay'
import { getAkun } from '@/services/spada.service'
import { useStore } from 'vuex'
export default {
  name: 'SpadaInfoAkun',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      user: users,
      profil: {},
      isRegister: true,
      loadAkun: false,
      fullPage: true
    }
  },
  computed: {
    isLoading: function () {
      return (this.loadAkun)
    }
  },
  created () {
    this.handleAkun()
  },
  methods: {
    async handleAkun () {
      this.loadAkun = true
      getAkun({ username: this.user.username }).then(response => {
        const records = response.records
        const akun = records.users
        console.log(akun.length)
        if (akun.length === 0) {
          this.isRegister = true
        } else {
          this.profil = akun[0]
          this.isRegister = false
        }
        this.loadAkun = false
      }).catch(error => {
        this.akunSpada = (error.response) ? {} : {}
        this.loadAkun = false
      })
    }
  }
}
</script>
